@import url(https://fonts.googleapis.com/css?family=Lato:wght@700&family=Raleway);
.products-main-div {
  margin: 0 auto;
  overflow: hidden;
  margin-top: 50px;
}
.products-main-div h1 {
  font-size: 32px;
  color: #ffffff;
  font-family: "Lato", sans-serif;
  font-weight: bolder;
  margin: 0 auto;
  text-align: center;
  background-color: #114b8a;
  border-radius: 50px;
  padding: 10px;
  width: 450px;
  padding: 15px 6px 15px 6px;
}
.products-main-div p {
  text-align: center;
  margin: 0 auto;
  color: #565656;
  font-size: 23px;
  margin-top: 20px;
  font-family: "Lato", sans-serif;
}
.card-landing-div {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 40px;
}
.landingSectionMain {
  margin: 0 auto;
}
.card {
  width: 400px;
  height: 380;
  cursor: pointer;
  margin-top: 30px;
  border: 1px solid #5656561d;
}
.card:hover {
  box-shadow: 0px 0px 19px #0000005c;
  transform: translateY(-2%);
  transition: all 0.45s ease-in-out;
}
.card-title {
  width: 398px;
  height: 80px;
  background-color: #f7f7f7f7;
  border-bottom: 1px solid #5656561d;
  margin: 0 auto;
}
.card-title h3 {
  color: #114b8a;
  padding: 10px 0px 0px 10px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  margin-top: 4px;
  margin: 0 auto;
}
.card-title p {
  font-size: 16px !important;
  font-family: "Raleway", sans-serif;
  letter-spacing: 1px;
  font-style: italic;
  padding: 0px 10px 0px 10px;
  text-align: left;
  margin-top: -7px;
  margin: 0 auto;
}
.card-img {
  width: 380px;
  height: 400px;
}
.card-footer {
  margin-top: -17px;
}
.card-button {
  background-color: transparent;
  padding-top: 12px;
  border: transparent;
  outline: none;
  /* border-radius: 0px 0px 0px 10px; */
  color: #114b8a;
  margin-top: -1% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  /* bottom: 0; */
  cursor: pointer;
  margin-left: 220px;
  font-weight: bold;
}
.card-btn-icon {
  padding-left: 5px;
  font-size: 20px;
  text-align: center;
}
.card-button:hover {
  /* background-color: #114b8aec; */
  color: #114b8ac5;
}
.card-ending-btn {
  background-color: #114b8a;
  padding-top: 10px;
  height: 40px;
  width: 250px;
  border: transparent;
  outline: none;
  border-radius: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  bottom: 0;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  letter-spacing: 1px;
  cursor: pointer;
  margin-top: 30px !important;
}
.card-ending-btn:hover {
  background-color: #0051ae;
  transform: translateY(-5px);
  transition: 850ms all ease-in-out;
}
a {
  text-decoration: none;
}

@media (min-width: 270px) and (max-width: 460px) {
  .products-main-div h1 {
    width: 300px;
    font-size: 21px;
    padding: 15px 6px 15px 6px;
  }
  .products-main-div p {
    font-size: 14px;
  }
  .card {
    width: 300px;
  }
  .card-img {
    width: 260px;
    height: 250px;
  }
  .card-title {
    width: 297px;
    height: 90px;
  }
  .card-button {
    margin-left: 130px;
  }
}
