.carousel {
  position: absolute;
  overflow: hidden;
  width: 100%;
}
.bg-img {
  width: 100vw;
  height: 550px;
  overflow: hidden;
}
@media (min-width: 401px) and (max-width: 600px) {
  .bg-img {
    height: 380px;
  }
}
@media (min-width: 200px) and (max-width: 400px) {
  .bg-img {
    height: 300px;
  }
}
