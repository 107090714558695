@import url(https://fonts.googleapis.com/css?family=Lato:wght@700&family=Raleway);
.products-page {
  background-color: #efefef62;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.products-banner-image {
  width: 100%;
  height: 100%;
}
.products-heading {
  margin-top: 100px;
}
.products-heading h1 {
  font: size 46px;
  color: #ffffff;
  font-family: "Lato", sans-serif;
  font-weight: bolder;
  margin: 0 auto;
  text-align: center;
  background-color: #114b8a;
  border-radius: 50px;
  padding: 10px;
  width: 350px;
}
.products-heading p {
  text-align: center;
  margin: 0 auto;
  color: #565656;
  font-size: 23px;
  margin-top: 20px;
  font-family: "Lato", sans-serif;
}
.products-page-body{
    margin:0 auto;
    margin-top:100px;
    display:flex;
    justify-content:center;
    flex-wrap: wrap;
}
.products-page-card{
    width:400px;
    height:100%;
    box-shadow: 0px 0px 16px #c1c1c1d2;
    border-radius: 3px;
    padding: 20px 0px 0px 0px;
    background-color: #ffffff20;
    cursor: pointer;
    margin:0 auto;
    text-align:center;
    margin-top: 30px;
}
.products-page-card:hover{
    background-color: #efefef64;
}
.products-page-image{
    width:300px;
    margin:0 auto;
    text-align: center;
}
.products-page-text{
    text-align:center;
    margin-top: 10px;
    color:#114b8a;
    font-family: 'Lato' , sans-serif;
    font-weight: bold;
}
.accordion{
    width:100%;
    margin:0 auto;
}
.accordion-toggle{
    display: flex;
    width:100%;
    justify-content:space-between;
}
.products-card-body{
  text-align: justify;
}
.products-nutrition-body{
  text-align: center;
}
.nutritionhead{
  background-color: #efefef;
  text-align: center;
  width: 100%;
}

.product-cell{
  padding: 0px 25px 0px 0px ;
  margin:0 auto;
  font-size: 14px;
  font-family: 'Lato' ,sans-serif;
}
@media (min-width:321px) and (max-width:420px){
  .products-page-card{
    width:300px
  }
}
@media (min-width:200px) and (max-width:310px){
  .products-page-card{
    width:250px;
  }
  .products-page-image{
    width:220px;
  }
  .product-cell{
  font-size: 10px;
  }
}