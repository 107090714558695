.pageNotFound {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
}
.pageNotFound img {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 700px;
  height: 600px;
  margin: 0 auto;
}
@media (min-width: 501px) and (max-width: 768px) {
  .pageNotFound img {
    width: 500px;
    margin: 0 auto;
    height: 300px;
  }
}
@media (min-width: 300px) and (max-width: 500px) {
  .pageNotFound img {
    width: 380px;
    margin: 0 auto;
    height: 280px;
  }
}
@media (min-width: 200px) and (max-width: 299px) {
  .pageNotFound img {
    width: 240px;
    margin: 0 auto;
    height: 190px;
  }
}
