@import url(https://fonts.googleapis.com/css?family=Lato:wght@700&family=Raleway);

.footer-container {
  width: 100%;
  height: 230px;
  background-color: #114b8a;
  margin-top: 60px;
  text-align: center;
  padding: 30px 0px 0px 0px;
}

.useful-links-panel {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
}
.page-link {
  background-color: transparent;
  border: none;
  text-align: center;
}
.page-link a {
  text-decoration: none;
  color: #ffffff;
  font-size: 15px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;
}
.page-link:hover {
  background-color: transparent;
  border: none;
}
.page-link a:hover {
  color: #c4c4c4;
  transition: 0.2s all ease-in-out;
}
.icons-div {
  margin: 0 auto;
  width: 60%;
  text-align: center;
}
.icons-div a {
  text-decoration: none;
  color: #fff;
}
.icon {
  color: #001c53;
  background-color: white;
  border-radius: 50%;
  font-size: 40px;
  margin: 10px;
  padding: 2px;
  border: 5px solid #fff;
}
.icon:hover {
  padding: 5px;
  color: #fff;
  background-color: #001c53;
  transition: ease-in-out 0.3s all;
  border: 2px solid #fff;
}
.icon:active {
  padding: 5px;
  color: #fff;
  background-color: #001c53;
  transition: ease-in-out 0.3s all;
  border: 2px solid #fff;
}
.copyright-div {
  letter-spacing: 1px;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
.copyright-div p {
  color: #fff;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  margin: 0 auto;
}
.copyright-div p span {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  margin: 0 auto;
}
a {
  text-decoration: none;
}
@media (min-width: 280px) and (max-width: 568px) {
  .page-link p {
    font-size: 10px;
  }
  .copyright-div p {
    font-size: 12px;
  }
}
