@import url(https://fonts.googleapis.com/css?family=Lato:wght@700&family=Raleway);
.main-div {
  margin: 0 auto;
  margin-top: 500px;
  overflow: hidden;
}
.main-div h1 {
  font: size 40px;
  color: #ffffff;
  font-family: "Lato", sans-serif;
  font-weight: bolder;
  margin: 0 auto;
  text-align: center;
  background-color: #114b8a;
  border-radius: 50px;
  padding: 10px;
  width: 450px;
}
.main-div p {
  text-align: center;
  margin: 0 auto;
  color: #565656;
  font-size: 23px;
  margin-top: 20px;
  font-family: "Lato", sans-serif;
}
.products-section-div {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 40px;
  overflow: hidden;
}
.card-main-div {
  margin: 0 auto;
  overflow: hidden;
}
.figure {
  font-family: "Monospace", sans-serif;
  position: relative;
  display: inline-flex;
  justify-content: center;
  overflow: hidden;
  /* margin: 10px; */
  width: 400px;
  height: 380px;
  color: #ffffff;
  font-size: 16px;
  text-align: left;
  border: 1px solid #114b8a;
  overflow: hidden;
  cursor: pointer;
}
.figure * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  overflow: hidden;
  cursor: pointer;
}
.figure:before {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  top: 100%;
  content: "";
  background-color: rgba(51, 51, 51, 0.9);
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
  overflow: hidden;
  cursor: pointer;
}

.figure img {
  vertical-align: top;
  max-width: 100%;
  backface-visibility: hidden;
  width: 410px;
  height: 380px;
  cursor: pointer;
}
.figure figcaption {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* z-index: 1; */
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
}
.figure h3,
.figure h5,
.figure p {
  margin: 0;
  opacity: 0;
  letter-spacing: 1px;
  overflow: hidden;
  cursor: pointer;
}
.figure h3 {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  text-transform: uppercase;
  font-weight: 400;
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
  margin-bottom: 5px;
  overflow: hidden;
  cursor: pointer;
}
.figure h5 {
  font-weight: normal;
  background-color: #ae895d;
  padding: 3px 10px;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  margin-bottom: 5px;
  overflow: hidden;
  cursor: pointer;
}
.figure p {
  color: #fff;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  text-transform: uppercase;
  font-weight: 400;
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
  margin-bottom: 5px;
  font-size: 10px;
  overflow: hidden;
  cursor: pointer;
}
.figure a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* z-index: 1; */
  overflow: hidden;
  cursor: pointer;
}
.figure:hover:before,
.figure.hover:before {
  top: 10px;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  overflow: hidden;
  cursor: pointer;
}
.figure:hover h3,
.figure.hover h3,
.figure:hover h5,
.figure.hover h5,
.figure:hover p,
.figure.hover p {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  cursor: pointer;
}
.figure:hover h3,
.figure.hover h3 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
  overflow: hidden;
  cursor: pointer;
}
.figure:hover h5,
.figure.hover h5 {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  overflow: hidden;
}
.figure:hover p,
.figure.hover p {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  overflow: hidden;
  cursor: pointer;
}

@media (min-width: 270px) and (max-width: 460px) {
  .main-div h1 {
    width: 300px;
    font-size: 27px;
  }
  .main-div p {
    font-size: 20px;
  }
  .figure {
    width: 300px;
    height: 270px;
  }
  .figure img {
    width: 310px;
    height: 270px;
  }

  .main-div {
    margin-top: 300px;
    overflow: hidden;
  }
}
