@import url(https://fonts.googleapis.com/css?family=Lato:wght@700&family=Raleway);
@import url(https://fonts.googleapis.com/css?family=Lato:wght@700&family=Raleway);
@import url(https://fonts.googleapis.com/css?family=Lato:wght@700&family=Raleway);
@import url(https://fonts.googleapis.com/css?family=Lato:wght@700&family=Raleway);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.navbar {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100px;
  margin-right: 30px;
  background-color: transparent;
  z-index: 1;
}
.navbar-brand img {
  width: 150px;
  margin-left: 30px;
}
.menu-bars {
  color: #067eff;
  font-size: 2rem;
  margin-right: 2rem;
}
.menu-bars:hover {
  color: #0061c8;
}
.menu-bars-close {
  color: white;
  font-size: 1.6rem;
  margin-left: 2rem;
  text-decoration: none;
  margin: 0 auto;
}
.menu-bars-close:hover {
  color: rgba(255, 255, 255, 0.664);
  transition: 500ms;
}
.nav-menu {
  background-color: #114b8a;
  width: 250px;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 950ms;
  overflow: hidden;
  padding-top: 30px;
}
.nav-menu.active {
  right: 0;
  transition: 450ms;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-animation: sidebar 2s infinite;
          animation: sidebar 2s infinite;
  z-index: 999;
  /* box-shadow: 0px -12px 20px #000000; */
}
.scrolled {
  height: 180px;
  display: -webkit-flex;
  display: flex;
  position: fixed;
  z-index: 1;
  width: 100%;
  background-color: #63abd1;
  overflow-x: hidden;
  overflow-y: hidden;
}
.nav-text {
  list-style: none;
  height: 60px;
  font-family: "Open Sans", Sans-serif;
  margin: 0 auto;
  padding-top: 25px;
}
.nav-text a {
  text-decoration: none;
  color: #fff;
  font-size: 10px;
  width: 95%;
  height: 100%;
  border-radius: 4px;
  font-size: 16px;
}

.nav-text a span {
  padding-left: 10px;
}
.nav-text a:hover {
  color: rgba(255, 255, 255, 0.664);
  transition: 500ms;
}
.nav-menu-items {
  width: 100%;
  text-decoration: none;
  list-style: none;
}
@-webkit-keyframes sidebar {
  10% {
    transition: cubic-bezier(0.165, 0.84, 0.44, 1) 2ms all ease-in-out;
  }
  50% {
    transition: cubic-bezier(0.165, 0.84, 0.44, 1) 2ms all ease-in-out;
  }
  100% {
    transition: cubic-bezier(0.165, 0.84, 0.44, 1) 2ms all ease-in-out;
  }
}
@keyframes sidebar {
  10% {
    transition: cubic-bezier(0.165, 0.84, 0.44, 1) 2ms all ease-in-out;
  }
  50% {
    transition: cubic-bezier(0.165, 0.84, 0.44, 1) 2ms all ease-in-out;
  }
  100% {
    transition: cubic-bezier(0.165, 0.84, 0.44, 1) 2ms all ease-in-out;
  }
}

.footer-container {
  width: 100%;
  height: 230px;
  background-color: #114b8a;
  margin-top: 60px;
  text-align: center;
  padding: 30px 0px 0px 0px;
}

.useful-links-panel {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0 auto;
}
.page-link {
  background-color: transparent;
  border: none;
  text-align: center;
}
.page-link a {
  text-decoration: none;
  color: #ffffff;
  font-size: 15px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;
}
.page-link:hover {
  background-color: transparent;
  border: none;
}
.page-link a:hover {
  color: #c4c4c4;
  transition: 0.2s all ease-in-out;
}
.icons-div {
  margin: 0 auto;
  width: 60%;
  text-align: center;
}
.icons-div a {
  text-decoration: none;
  color: #fff;
}
.icon {
  color: #001c53;
  background-color: white;
  border-radius: 50%;
  font-size: 40px;
  margin: 10px;
  padding: 2px;
  border: 5px solid #fff;
}
.icon:hover {
  padding: 5px;
  color: #fff;
  background-color: #001c53;
  transition: ease-in-out 0.3s all;
  border: 2px solid #fff;
}
.icon:active {
  padding: 5px;
  color: #fff;
  background-color: #001c53;
  transition: ease-in-out 0.3s all;
  border: 2px solid #fff;
}
.copyright-div {
  letter-spacing: 1px;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
.copyright-div p {
  color: #fff;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  margin: 0 auto;
}
.copyright-div p span {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  margin: 0 auto;
}
a {
  text-decoration: none;
}
@media (min-width: 280px) and (max-width: 568px) {
  .page-link p {
    font-size: 10px;
  }
  .copyright-div p {
    font-size: 12px;
  }
}

.contact-page {
  background-color: #efefef62;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.contact-banner-image {
  width: 100%;
  height: 100%;
}
.contact-heading {
  margin-bottom: 70px;
  margin-top: 100px;
}
.contact-heading h1 {
  font: size 46px;
  color: #ffffff;
  font-family: "Lato", sans-serif;
  font-weight: bolder;
  margin: 0 auto;
  text-align: center;
  background-color: #114b8a;
  border-radius: 50px;
  padding: 10px;
  width: 350px;
}
.contact-heading p {
  text-align: center;
  margin: 0 auto;
  color: #565656;
  font-size: 23px;
  margin-top: 20px;
  font-family: "Lato", sans-serif;
}
.contact-page-content {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.contact-fresho {
  /* margin: 0 auto; */
  margin-top: 23px;
  height: 100%;
  border: 2px solid #114b8a;
  border-radius: 4px;
  padding: 30px;
}

.contact-fresho-heading {
  color: #114b8a;
  font-family: "Lato", sans-serif;
  font-weight: bolder;
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 5px;
}
.contact-fresho-address {
  color: #565656d4;
  width: 180px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  letter-spacing: 1px;
}
.contact-fresho-phone {
  color: #565656d4;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  word-spacing: 3px;
  letter-spacing: 1px;
}
.contact-fresho-email {
  color: #565656d4;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  word-spacing: 3px;
  letter-spacing: 1px;
  margin-bottom: 0px;
}
.contact-body {
  /* margin: 0 auto; */
  text-align: center;
}
.nameInput {
  width: 410px;
  height: 60px;
  border-radius: 50px;
  outline: none;
  font-size: 16px;
  border: 2px solid #114b8a;
  padding: 30px;
  color: #114b8a;
  display: block;
  margin: 0 auto;
  margin-top: 20px;
}
.nameInput::-webkit-input-placeholder {
  color: #114b8a;
}
.nameInput:-ms-input-placeholder {
  color: #114b8a;
}
.nameInput::placeholder {
  color: #114b8a;
}
.emailInput {
  width: 410px;
  height: 60px;
  border-radius: 50px;
  outline: none;
  font-size: 16px;
  border: 2px solid #114b8a;
  padding: 30px;
  color: #114b8a;
  display: block;
  margin: 0 auto;
  margin-top: 20px;
}
.emailInput::-webkit-input-placeholder {
  color: #114b8a;
}
.emailInput:-ms-input-placeholder {
  color: #114b8a;
}
.emailInput::placeholder {
  color: #114b8a;
}
.numberInput {
  width: 410px;
  height: 60px;
  border-radius: 50px;
  outline: none;
  font-size: 16px;
  border: 2px solid #114b8a;
  padding: 30px;
  color: #114b8a;
  margin: 0 auto;
  margin-top: 20px;
  display: block;
}
.numberInput::-webkit-input-placeholder {
  color: #114b8a;
}
.numberInput:-ms-input-placeholder {
  color: #114b8a;
}
.numberInput::placeholder {
  color: #114b8a;
}
.textArea {
  width: 410px;

  height: 200px;
  border-radius: 30px;
  outline: none;
  font-size: 16px;
  border: 2px solid #114b8a;
  padding: 20px 30px;
  color: #114b8a;
  resize: none;
  margin: 0 auto;
  display: block;
  margin-top: 20px;
}
.textArea::-webkit-input-placeholder {
  color: #114b8a;
}
.textArea:-ms-input-placeholder {
  color: #114b8a;
}
.textArea::placeholder {
  color: #114b8a;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.opening-hr {
  border: 0;
  margin: 0 auto;
  text-align: center;
  height: 2px;
  width: 300px;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0)
  );
  /* margin-left: 0.1em; */
}
.submitBtn {
  background-color: #114b8a;
  padding-top: 10px;
  height: 40px;
  width: 250px;
  border: transparent;
  outline: none;
  border-radius: 20px;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0 auto;
  bottom: 0;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  letter-spacing: 1px;
  cursor: pointer;
  margin-top: 30px !important;
}
.contact-btn-icon {
  padding-left: 5px;
  font-size: 20px;
  text-align: center;
}
.submitBtn:hover {
  background-color: #0051ae;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  transition: 850ms all ease-in-out;
}

@media (min-width: 301px) and (max-width: 400px) {
  .nameInput {
    width: 300px;
    height: 40px;
  }
  .emailInput {
    width: 300px;
    height: 40px;
  }
  .numberInput {
    width: 300px;
    height: 40px;
  }
  .textArea {
    width: 300px;
  }
  .contact-heading h1 {
    width: 300px;
  }
  .contact-fresho {
    margin: 0 auto;
  }
  .contact-body {
    margin: 0 auto;
  }
  .contact-fresho-heading {
    font-size: 13px;
  }
  .contact-fresho-phone,
  .contact-fresho-email,
  .contact-fresho-address {
    font-size: 11px;
  }
}
@media (min-width: 100px) and (max-width: 300px) {
  .nameInput {
    width: 250px;
    height: 20px;
  }
  .emailInput {
    width: 250px;
    height: 20px;
  }
  .numberInput {
    width: 250px;
    height: 20px;
  }
  .textArea {
    width: 250px;
  }
  .contact-heading h1 {
    width: 250px;
  }
  .contact-fresho {
    margin: 0 auto;
  }
  .contact-body {
    margin: 0 auto;
  }
  .contact-fresho-heading {
    font-size: 13px;
  }
  .contact-fresho-phone,
  .contact-fresho-email,
  .contact-fresho-address {
    font-size: 11px;
  }
}

.products-page {
  background-color: #efefef62;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.products-banner-image {
  width: 100%;
  height: 100%;
}
.products-heading {
  margin-top: 100px;
}
.products-heading h1 {
  font: size 46px;
  color: #ffffff;
  font-family: "Lato", sans-serif;
  font-weight: bolder;
  margin: 0 auto;
  text-align: center;
  background-color: #114b8a;
  border-radius: 50px;
  padding: 10px;
  width: 350px;
}
.products-heading p {
  text-align: center;
  margin: 0 auto;
  color: #565656;
  font-size: 23px;
  margin-top: 20px;
  font-family: "Lato", sans-serif;
}
.products-page-body{
    margin:0 auto;
    margin-top:100px;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}
.products-page-card{
    width:400px;
    height:100%;
    box-shadow: 0px 0px 16px #c1c1c1d2;
    border-radius: 3px;
    padding: 20px 0px 0px 0px;
    background-color: #ffffff20;
    cursor: pointer;
    margin:0 auto;
    text-align:center;
    margin-top: 30px;
}
.products-page-card:hover{
    background-color: #efefef64;
}
.products-page-image{
    width:300px;
    margin:0 auto;
    text-align: center;
}
.products-page-text{
    text-align:center;
    margin-top: 10px;
    color:#114b8a;
    font-family: 'Lato' , sans-serif;
    font-weight: bold;
}
.accordion{
    width:100%;
    margin:0 auto;
}
.accordion-toggle{
    display: -webkit-flex;
    display: flex;
    width:100%;
    -webkit-justify-content:space-between;
            justify-content:space-between;
}
.products-card-body{
  text-align: justify;
}
.products-nutrition-body{
  text-align: center;
}
.nutritionhead{
  background-color: #efefef;
  text-align: center;
  width: 100%;
}

.product-cell{
  padding: 0px 25px 0px 0px ;
  margin:0 auto;
  font-size: 14px;
  font-family: 'Lato' ,sans-serif;
}
@media (min-width:321px) and (max-width:420px){
  .products-page-card{
    width:300px
  }
}
@media (min-width:200px) and (max-width:310px){
  .products-page-card{
    width:250px;
  }
  .products-page-image{
    width:220px;
  }
  .product-cell{
  font-size: 10px;
  }
}
.pageNotFound {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
}
.pageNotFound img {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  width: 700px;
  height: 600px;
  margin: 0 auto;
}
@media (min-width: 501px) and (max-width: 768px) {
  .pageNotFound img {
    width: 500px;
    margin: 0 auto;
    height: 300px;
  }
}
@media (min-width: 300px) and (max-width: 500px) {
  .pageNotFound img {
    width: 380px;
    margin: 0 auto;
    height: 280px;
  }
}
@media (min-width: 200px) and (max-width: 299px) {
  .pageNotFound img {
    width: 240px;
    margin: 0 auto;
    height: 190px;
  }
}

.banner-image {
  margin-top: 90px;
}
.banner-image img {
  width: 100%;
  height: 100%;
}

.carousel {
  position: absolute;
  overflow: hidden;
  width: 100%;
}
.bg-img {
  width: 100vw;
  height: 550px;
  overflow: hidden;
}
@media (min-width: 401px) and (max-width: 600px) {
  .bg-img {
    height: 380px;
  }
}
@media (min-width: 200px) and (max-width: 400px) {
  .bg-img {
    height: 300px;
  }
}

.main-div {
  margin: 0 auto;
  margin-top: 500px;
  overflow: hidden;
}
.main-div h1 {
  font: size 40px;
  color: #ffffff;
  font-family: "Lato", sans-serif;
  font-weight: bolder;
  margin: 0 auto;
  text-align: center;
  background-color: #114b8a;
  border-radius: 50px;
  padding: 10px;
  width: 450px;
}
.main-div p {
  text-align: center;
  margin: 0 auto;
  color: #565656;
  font-size: 23px;
  margin-top: 20px;
  font-family: "Lato", sans-serif;
}
.products-section-div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 40px;
  overflow: hidden;
}
.card-main-div {
  margin: 0 auto;
  overflow: hidden;
}
.figure {
  font-family: "Monospace", sans-serif;
  position: relative;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
          justify-content: center;
  overflow: hidden;
  /* margin: 10px; */
  width: 400px;
  height: 380px;
  color: #ffffff;
  font-size: 16px;
  text-align: left;
  border: 1px solid #114b8a;
  overflow: hidden;
  cursor: pointer;
}
.figure * {
  box-sizing: border-box;
  transition: all 0.25s ease;
  overflow: hidden;
  cursor: pointer;
}
.figure:before {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  top: 100%;
  content: "";
  background-color: rgba(51, 51, 51, 0.9);
  transition: all 0.25s ease;
  transition-delay: 0.25s;
  overflow: hidden;
  cursor: pointer;
}

.figure img {
  vertical-align: top;
  max-width: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  width: 410px;
  height: 380px;
  cursor: pointer;
}
.figure figcaption {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* z-index: 1; */
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  overflow: hidden;
  cursor: pointer;
}
.figure h3,
.figure h5,
.figure p {
  margin: 0;
  opacity: 0;
  letter-spacing: 1px;
  overflow: hidden;
  cursor: pointer;
}
.figure h3 {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  text-transform: uppercase;
  font-weight: 400;
  transition-delay: 0.05s;
  margin-bottom: 5px;
  overflow: hidden;
  cursor: pointer;
}
.figure h5 {
  font-weight: normal;
  background-color: #ae895d;
  padding: 3px 10px;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  transition-delay: 0s;
  margin-bottom: 5px;
  overflow: hidden;
  cursor: pointer;
}
.figure p {
  color: #fff;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  text-transform: uppercase;
  font-weight: 400;
  transition-delay: 0.05s;
  margin-bottom: 5px;
  font-size: 10px;
  overflow: hidden;
  cursor: pointer;
}
.figure a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* z-index: 1; */
  overflow: hidden;
  cursor: pointer;
}
.figure:hover:before,
.figure.hover:before {
  top: 10px;
  transition-delay: 0s;
  overflow: hidden;
  cursor: pointer;
}
.figure:hover h3,
.figure.hover h3,
.figure:hover h5,
.figure.hover h5,
.figure:hover p,
.figure.hover p {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  cursor: pointer;
}
.figure:hover h3,
.figure.hover h3 {
  transition-delay: 0.3s;
  overflow: hidden;
  cursor: pointer;
}
.figure:hover h5,
.figure.hover h5 {
  transition-delay: 0.2s;
  overflow: hidden;
}
.figure:hover p,
.figure.hover p {
  transition-delay: 0.2s;
  overflow: hidden;
  cursor: pointer;
}

@media (min-width: 270px) and (max-width: 460px) {
  .main-div h1 {
    width: 300px;
    font-size: 27px;
  }
  .main-div p {
    font-size: 20px;
  }
  .figure {
    width: 300px;
    height: 270px;
  }
  .figure img {
    width: 310px;
    height: 270px;
  }

  .main-div {
    margin-top: 300px;
    overflow: hidden;
  }
}

.products-main-div {
  margin: 0 auto;
  overflow: hidden;
  margin-top: 50px;
}
.products-main-div h1 {
  font-size: 32px;
  color: #ffffff;
  font-family: "Lato", sans-serif;
  font-weight: bolder;
  margin: 0 auto;
  text-align: center;
  background-color: #114b8a;
  border-radius: 50px;
  padding: 10px;
  width: 450px;
  padding: 15px 6px 15px 6px;
}
.products-main-div p {
  text-align: center;
  margin: 0 auto;
  color: #565656;
  font-size: 23px;
  margin-top: 20px;
  font-family: "Lato", sans-serif;
}
.card-landing-div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 40px;
}
.landingSectionMain {
  margin: 0 auto;
}
.card {
  width: 400px;
  height: 380;
  cursor: pointer;
  margin-top: 30px;
  border: 1px solid #5656561d;
}
.card:hover {
  box-shadow: 0px 0px 19px #0000005c;
  -webkit-transform: translateY(-2%);
          transform: translateY(-2%);
  transition: all 0.45s ease-in-out;
}
.card-title {
  width: 398px;
  height: 80px;
  background-color: #f7f7f7f7;
  border-bottom: 1px solid #5656561d;
  margin: 0 auto;
}
.card-title h3 {
  color: #114b8a;
  padding: 10px 0px 0px 10px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  margin-top: 4px;
  margin: 0 auto;
}
.card-title p {
  font-size: 16px !important;
  font-family: "Raleway", sans-serif;
  letter-spacing: 1px;
  font-style: italic;
  padding: 0px 10px 0px 10px;
  text-align: left;
  margin-top: -7px;
  margin: 0 auto;
}
.card-img {
  width: 380px;
  height: 400px;
}
.card-footer {
  margin-top: -17px;
}
.card-button {
  background-color: transparent;
  padding-top: 12px;
  border: transparent;
  outline: none;
  /* border-radius: 0px 0px 0px 10px; */
  color: #114b8a;
  margin-top: -1% !important;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  /* bottom: 0; */
  cursor: pointer;
  margin-left: 220px;
  font-weight: bold;
}
.card-btn-icon {
  padding-left: 5px;
  font-size: 20px;
  text-align: center;
}
.card-button:hover {
  /* background-color: #114b8aec; */
  color: #114b8ac5;
}
.card-ending-btn {
  background-color: #114b8a;
  padding-top: 10px;
  height: 40px;
  width: 250px;
  border: transparent;
  outline: none;
  border-radius: 20px;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0 auto;
  bottom: 0;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  letter-spacing: 1px;
  cursor: pointer;
  margin-top: 30px !important;
}
.card-ending-btn:hover {
  background-color: #0051ae;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  transition: 850ms all ease-in-out;
}
a {
  text-decoration: none;
}

@media (min-width: 270px) and (max-width: 460px) {
  .products-main-div h1 {
    width: 300px;
    font-size: 21px;
    padding: 15px 6px 15px 6px;
  }
  .products-main-div p {
    font-size: 14px;
  }
  .card {
    width: 300px;
  }
  .card-img {
    width: 260px;
    height: 250px;
  }
  .card-title {
    width: 297px;
    height: 90px;
  }
  .card-button {
    margin-left: 130px;
  }
}

